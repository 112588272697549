const APP_V2_BASE_URL = process.env.VUE_APP_V2_BASE_URL;

export default [
  {
    path: '/accounts',
    name: 'Accounts',
    meta: {
      protected: true
    },
    redirect: () => {
      window.location.href = APP_V2_BASE_URL
        ? `${APP_V2_BASE_URL}/accounts`
        : '/';
    }
  }
];
