import { getDomainTitle } from '@/utils/get-domain-title';
const domainTitle = getDomainTitle();

const APP_V2_BASE_URL = process.env.VUE_APP_V2_BASE_URL;

export default [
  {
    path: '/card',
    name: 'Card',
    meta: {
      protected: true
    },
    component: () => import('@/components/adcard'),
    children: [
      {
        path: '/',
        name: 'CardDashboard',
        beforeEnter() {
          window.location.href = APP_V2_BASE_URL
            ? `${APP_V2_BASE_URL}/dashboard`
            : '/';
        }
      },
      {
        path: '/card',
        redirect: '/'
      },
      {
        path: 'cards',
        name: 'Cards',
        component: () => import('@/components/adcard/adcard-cards.vue'),
        meta: {
          title: `Cards - ${domainTitle}`
        }
      },
      {
        path: 'transactions',
        name: 'CardTransactions',
        beforeEnter() {
          window.location.href = APP_V2_BASE_URL
            ? `${APP_V2_BASE_URL}/transactions`
            : '/';
        },
        children: [
          {
            path: 'all',
            name: 'AllTransactions',
            component: () =>
              import('@/components/adcard/adcard-transactions-all.vue'),
            meta: {
              title: `Transactions - ${domainTitle}`
            }
          },
          {
            path: 'pending',
            name: 'PendingTransactions',
            component: () =>
              import('@/components/adcard/adcard-transactions-pending.vue'),
            meta: {
              title: `Pending Transactions - ${domainTitle}`
            }
          },
          {
            path: '',
            redirect: 'all'
          }
        ]
      },
      {
        path: 'payments',
        name: 'CardPayments',
        component: () =>
          import('@/components/adcard/lithic/payments/payment-index.vue'),

        meta: {
          title: `Payments - ${domainTitle}`
        }
      },
      {
        path: 'installments',
        name: 'CardInstallments',
        component: () =>
          import('@/components/adcard/e6/payments/payment-index.vue'),
        meta: {
          title: `Installments - ${domainTitle}`
        },
        children: [
          {
            path: 'past',
            name: 'PastPayments',
            component: () =>
              import('@/components/adcard/e6/payments/payment-past.vue'),
            meta: {
              title: `Installments - ${domainTitle}`
            }
          },
          {
            path: 'scheduled',
            name: 'ScheduledPayments',
            component: () =>
              import('@/components/adcard/e6/payments/payment-scheduled.vue'),
            meta: {
              title: `Installments - ${domainTitle}`
            }
          },
          {
            path: '',
            redirect: 'past'
          }
        ]
      },
      {
        path: 'statements',
        name: 'CardStatements',
        beforeEnter() {
          window.location.href = APP_V2_BASE_URL
            ? `${APP_V2_BASE_URL}/statements`
            : '/';
        }
      },
      {
        path: 'insights',
        name: 'CardInsights',
        component: () => import('@/components/adcard/adcard-insights.vue'),
        meta: {
          title: `Insights - ${domainTitle}`
        }
      },
      {
        path: 'rewards',
        name: 'CardRewards',
        redirect: 'rewards/overview',
        component: () =>
          import('@/components/adcard/rewards/rewards-index.vue'),
        meta: {
          title: `Rewards - ${domainTitle}`,
          ownerOnly: true
        },
        children: [
          {
            path: 'overview',
            name: 'RewardsOverview',
            beforeEnter() {
              window.location.href = APP_V2_BASE_URL
                ? `${APP_V2_BASE_URL}/rewards`
                : '/';
            }
          },
          {
            path: 'cards',
            name: 'RewardsCards',
            component: () =>
              import(
                '@/components/adcard/rewards/rewards-cards-and-transactions.vue'
              ),
            meta: {
              title: `Rewards - ${domainTitle}`
            }
          },
          {
            path: 'accrued',
            name: 'RewardsAccrued',
            beforeEnter() {
              window.location.href = APP_V2_BASE_URL
                ? `${APP_V2_BASE_URL}/rewards/accrued`
                : '/';
            }
          },
          {
            path: 'terms',
            name: 'RewardsTerms',
            beforeEnter() {
              window.location.href = APP_V2_BASE_URL
                ? `${APP_V2_BASE_URL}/rewards/terms`
                : '/';
            }
          },
          {
            path: 'redemption',
            name: 'RewardsRedemption',
            component: () =>
              import('@/components/adcard/rewards/rewards-wallet.vue'),
            meta: {
              title: `Rewards - ${domainTitle}`
            }
          },
          {
            path: 'earnings',
            name: 'RewardsEarning',
            component: () =>
              import('@/components/adcard/rewards/rewards-payouts-summary.vue'),
            meta: {
              title: `Rewards - ${domainTitle}`
            }
          }
        ]
      }
    ]
  }
];
