import { render, staticRenderFns } from "./sardine.vue?vue&type=template&id=00512e54"
import script from "./sardine.vue?vue&type=script&lang=js"
export * from "./sardine.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.11.1_babel-core@7.0.0-bridge.0_@babel+core@7.26.10__css-loader@6.11.0_web_b9cb507c6f4080a39280a05cef943207/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports