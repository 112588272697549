import { render, staticRenderFns } from "./global-notification.vue?vue&type=template&id=626b10cb&scoped=true"
import script from "./global-notification.vue?vue&type=script&lang=js"
export * from "./global-notification.vue?vue&type=script&lang=js"
import style0 from "./global-notification.vue?vue&type=style&index=0&id=626b10cb&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.11.1_babel-core@7.0.0-bridge.0_@babel+core@7.26.10__css-loader@6.11.0_web_b9cb507c6f4080a39280a05cef943207/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "626b10cb",
  null
  
)

export default component.exports